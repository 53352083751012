import {
  rest,
  RestRequest,
  DefaultBodyType,
  ResponseComposition,
  RestContext,
  PathParams,
} from 'msw';
import {
  indexControllerFullResult,
  indexControllerSuperSearchDisable,
  indexControllerNoCustomerPortalUrl,
  disableIndexControllerKey,
} from './index';
import {
  createFullResult,
  mainFullResult,
  systemAdministrationMenuFullResult,
  pageSettingMenuFullResult,
  personalSettingsMenuFullResult,
} from './menus';
import {
  milestonesEmptyResult,
  milestonesPage1,
  milestonesPage2,
  milestonesPage3,
} from './milestones';
import TimeTrackerPageSettingOnFullResult from './pageSettings/timeTracker/timeTrackerOnFullResult';
import TimeTrackerPageSettingOffFullResult from './pageSettings/timeTracker/timeTrackerOffFullResult';
import TimeTrackerResult from './timeTracker/active/timeTrackerWithTaskResult';
import {
  userSettingsEnglishFullResult,
  userSettingsDanishFullResult,
  userSettingsSwedishFullResult,
  userSettingsNorwegianFullResult,
  userSettingsGermanFullResult,
  userSettingsDutchFullResult,
  userSettingsFinnishFullResult,
  userSettingsRussianFullResult,
} from './userSettings';
import userPreviewFeaturesFullResult from './userPreviewFeatures/userPreviewFeaturesFullResult';
import { userMeFullResult } from './users/me';
import userPreviewFeaturesDesignSevenDisabledResult from './userPreviewFeatures/userPreviewFeaturesDesignSevenDisabledResult';
import { bookmarkEmptyResult, bookmarkFullResult, generateBookmark } from './bookmarks';
import {
  searchCategoryAllNotSelectResult,
  searchCategoryEmptyResult,
  searchCategoryPartiallySelectResult,
} from './search/categories';
import {
  searchEmptyResult,
  searchFullResult,
  searchSomeResult,
  searchFilterResult,
} from './search';
import { timeRegistrationsFullResult } from './reports';
import designSevenFullResult, {
  removeDesignSevenPage,
  removeDesignSevenSystemAdminPage,
} from './pages/designSevenAPI';
import timeRegistrationAccuracyFullResult from './widget/timeRegistrationAccuracy/timeRegistrationAccuracyFullResult';
import billablePercentageFullResult from './widget/billablePercentage/billablePercentageFullResult';
import externalPercentageFullResult from './widget/externalPercentage/externalPercentageFullResult';
import getGroupByResourceMonthFullResult from './resourcePlanner/getGroupByResourceMonthFullResult';
import getGroupByResourceEmptyResult from './resourcePlanner/getGroupByResourceEmptyResult';
import { newsFeedFullResult, newsFeedNoResults } from './newsFeed';
import {
  filterFullResult,
  filterSearchProjectsFullResult,
  filterEmployeesOverview,
} from './filter';
import dropdownBuilder from './dropdown/dropdownBuilder';
import userSettingsShowSignUpButton from './userSettings/userSettingsShowSignUpButton';
import getResourceViewOptions from './resourcePlanner/getResourceViewOptions';
import { signalRConfigFullResult } from './resourcePlanner';
import getGroupByResourceDayFullResult from './resourcePlanner/getGroupByResourceDayFullResult';
import getGroupByResourceWeekFullResult from './resourcePlanner/getGroupByResourceWeekFullResult';
import getGroupByResourceQuarterFullResult from './resourcePlanner/getGroupByResourceQuarterFullResult';
import getGroupByWorkItemDayFullResult from './resourcePlanner/getGroupByWorkItemDayFullResult';
import getGroupByWorkItemMonthFullResult from './resourcePlanner/getGroupByWorkItemMonthFullResult';
import getGroupByWorkItemQuarterFullResult from './resourcePlanner/getGroupByWorkItemQuarterFullResult';
import getGroupByWorkItemWeekFullResult from './resourcePlanner/getGroupByWorkItemWeekFullResult';
import getSearchResourceByName from './resourcePlanner/getSearchResourceByName';
import getSearchTaskByName from './resourcePlanner/getSearchTaskByName';
import getMigrationStatusComplete from './resourcePlanner/getMigrationStatusComplete';
import { getSavedViewResult } from './resourcePlanner/savedView';
import { timeTrackingSettingsDecimal, timeTrackingSettingsTime } from './timeTrackingSettings';
import { internalMessageCanEdit } from './widget/internalMessage';
import {
  getPeriodsFullResult,
  getDistinctCurrenciesFullResult,
  getMatrixActionsFullResult,
  getMatrixExchangeRateFullResult,
  matrixExchangeRateResultSetForPeriodId312,
  matrixExchangeRateResultSetForPeriodId311,
  postManualPeriodFullResult,
  getEmptyResult,
} from './exchangeRate';
import { notificationCenterFullResult } from './notificationCenter';
import {
  reportingWidgetGetAll,
  getAverageHourlyRateFullResult,
  getInvoicedPerMonthFullResult,
  getOverdueInvoicesFullResult,
  getPlannedAbsenceFullResult,
  getProjectEconomyHealthFullResult,
  getTimeTrackingDelayFullResult,
  getVacationCalculationFullResult,
  getAddedValuePerMonthFullResult,
  getAddedValueBarPerMonthFullResult,
  getPrivilegeOptionsFullResult,
} from './reportingWidget';
import { employeesFullResult } from './employees/employeesFullResult';
import { competenceGroupsFullResult } from './competenceGroups/competenceGroupsFullResult';
import { userFullResult } from './user/userFullResult';
import { employeeCompetencesFullResult } from './employeeCompetences/employeeCompetencesFullResult';
import { employeeFullResult } from './employee/employeeFullResult';
import { employeeCompetencesAvailableFullResult } from './employeeCompetences/employeeCompetencesAvailableFullResult';
import { competencesByGroupFullResult } from './competences';
import {
  getFrontPageFeaturesFullResult,
  getResourceManagementFeaturesFullResult,
} from './features';
import {
  getCapacity,
  getCompetenceContents,
  getEmployeeAllocation,
  getHourlyRates,
  getRecentProjects,
  getRecentResources,
  getRecentTasks,
  getUnallocatedEmployees,
  getUnallocatedTasks,
  postEmployeeSearch,
  postProjectSearch,
} from './resourcePlanner/assignFlow';
import { getRevenueForecastViewOptions, getRevenueForecast } from './revenueForecast';
import { getActions, getSearchProjects, getTimeRegistrations } from './projects';
import { getSavedViewResult as getSearchProjectSaveView } from './projects/savedView';

interface IEventHandler {
  req: RestRequest<DefaultBodyType, PathParams>;
  res: ResponseComposition<any>;
  ctx: RestContext;
  responseData: Record<string, object | null>;
}

const eventHandler = ({ req, res, ctx, responseData }: IEventHandler) => {
  const urlStatus = req.url.searchParams.get('status') ?? '200';

  switch (urlStatus) {
    case '401':
      return res(ctx.status(401), ctx.json({ error: 'unauthorize user' }));
    case '500':
      return res(ctx.status(500), ctx.json({ error: 'internal server error' }));

    default:
      return res(ctx.json(responseData[urlStatus]));
  }
};

const changableValue = {
  isTrackingTime: true,
  bookmark: [] as any[],
};

const milestonesPage = (page: string) => {
  if (page === '1') {
    return milestonesPage1;
  }
  if (page === '2') {
    return milestonesPage2;
  }
  if (page === '3') {
    return milestonesPage3;
  }
  return null;
};

const assignFlowHandlers = [
  rest.get('http://mockapi/api/v2/competence/content', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getCompetenceContents,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/contract-hourly-rates/dropdown-content', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getHourlyRates,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/projects/:projectId/employee-allocation', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getEmployeeAllocation,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/projects/:projectId/employee-unallocated', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getUnallocatedEmployees,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/projects/:projectId/tasks-unallocated', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getUnallocatedTasks,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/projects/recently-registered', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getRecentProjects,
      },
    }),
  ),
  rest.get(
    'http://mockapi/api/v2/resource-planner/resources/recently-registered',
    (req, res, ctx) =>
      eventHandler({
        req,
        res,
        ctx,
        responseData: {
          '200': getRecentResources,
        },
      }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/tasks/recently-registered', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getRecentTasks,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/user/:userId/capacity', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getCapacity,
      },
    }),
  ),
  rest.post('http://mockapi/api/v2/resource-planner/employee-search', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': postEmployeeSearch,
      },
    }),
  ),
  rest.post('http://mockapi/api/v2/resource-planner/project-search', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': postProjectSearch,
      },
    }),
  ),
  rest.post('http://mockapi/api/v2/resource-planner/work-item-relationship/bulk', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': ['OK'],
      },
    }),
  ),
];

const handlers = [
  ...assignFlowHandlers,
  rest.get('http://mockapi/api/v2', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': indexControllerFullResult,
        '201': indexControllerSuperSearchDisable,
        '202': disableIndexControllerKey({
          propertyKeys: [],
          relKeys: ['WidgetData'],
          actionKeys: [],
        }),
        '203': indexControllerNoCustomerPortalUrl,
        '204': null,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/menus/create', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': createFullResult } }),
  ),
  rest.get('http://mockapi/api/v2/menus/main', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': mainFullResult } }),
  ),
  rest.get('http://mockapi/api/v2/pages/design-seven', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': designSevenFullResult,
        '201': removeDesignSevenPage(['DesignSevenFrontPage']),
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/menus/system-administration', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': systemAdministrationMenuFullResult,
        '201': removeDesignSevenSystemAdminPage([
          'SystemAdministrationIntercompanyExchangeRateSetting',
        ]),
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/menus/page-settings', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': pageSettingMenuFullResult },
    }),
  ),
  rest.get('http://mockapi/api/v2/milestones/incomplete', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': milestonesPage(req.url.searchParams.get('$page') ?? ''),
        '204': milestonesEmptyResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/page-settings/TimeTrackingBase', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': TimeTrackerPageSettingOnFullResult,
        '204': TimeTrackerPageSettingOffFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/time-tracker/active', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': changableValue.isTrackingTime ? TimeTrackerResult : null,
        '204': null,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/filters/:dropDownName/content', (req, res, ctx) => {
    const {
      params: { dropDownName },
      url: { searchParams },
    } = req;
    const searchText = searchParams.get('searchText') ?? undefined;

    return eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': dropdownBuilder(dropDownName as string, 10, searchText),
        '204': null,
      },
    });
  }),
  rest.post('http://mockApi/api/v2/time-tracker/stop/*', (req, res, ctx) => {
    changableValue.isTrackingTime = false;
    return eventHandler({ req, res, ctx, responseData: { '200': {} } });
  }),
  rest.get('http://mockApi/api/v2/menus/personal-settings', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': personalSettingsMenuFullResult },
    }),
  ),
  rest.get('http://mockapi/api/v2/user-settings', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': userSettingsEnglishFullResult,
        '201': userSettingsDanishFullResult,
        '202': userSettingsSwedishFullResult,
        '203': userSettingsNorwegianFullResult,
        '204': userSettingsGermanFullResult,
        '205': userSettingsDutchFullResult,
        '206': userSettingsFinnishFullResult,
        '207': userSettingsRussianFullResult,
        '208': userSettingsShowSignUpButton,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/user-preview-features', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': userPreviewFeaturesFullResult,
        '204': userPreviewFeaturesDesignSevenDisabledResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/search', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': searchFilterResult(
          req.url.searchParams.get('searchtext') ?? '',
          req.url.searchParams.get('categories') ?? '',
        ),
        '201': searchSomeResult,
        '202': searchFullResult,
        '204': searchEmptyResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/users/me', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': userMeFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v1/user-setting', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': timeTrackingSettingsDecimal,
        '204': timeTrackingSettingsTime,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/filters/list/DesignSevenExampleReport', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': filterFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/filters/list/RevenueForecast', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': filterFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/filters/list/EmployeesOverview', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': filterEmployeesOverview,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/filters/list/DesignSevenSearchProject', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': filterSearchProjectsFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/bookmarks', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': {
          Entities: [...bookmarkFullResult.Entities, ...changableValue.bookmark],
          Links: bookmarkFullResult.Links,
        },
        '204': bookmarkEmptyResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/notification-center', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': notificationCenterFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/widget-data/time-registration-accuracy', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': timeRegistrationAccuracyFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/widget-data/external-percentage', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': externalPercentageFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/widget-data/billable-percentage', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': billablePercentageFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/settings/frontpage-internal-message', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': internalMessageCanEdit,
      },
    }),
  ),
  rest.post(
    'http://mockapi/api/v2/resource-planner/partial-group-by-work-item-first-load',
    (req, res, ctx) => {
      if (req.url.search.indexOf('periodtypes=day') > -1) {
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByWorkItemDayFullResult,
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      if (req.url.search.indexOf('periodtypes=week') > -1) {
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByWorkItemWeekFullResult,
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      if (req.url.search.indexOf('periodtypes=quarter') > -1) {
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByWorkItemQuarterFullResult,
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      return eventHandler({
        req,
        res,
        ctx,
        responseData: {
          '200': getGroupByWorkItemMonthFullResult,
          '204': getGroupByResourceEmptyResult,
        },
      });
    },
  ),
  rest.post(
    'http://mockapi/api/v2/resource-planner/partial-group-by-employee-first-load',
    (req, res, ctx) => {
      if (req.url.search.indexOf('periodtypes=day') > -1) {
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByResourceDayFullResult,
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      if (req.url.search.indexOf('periodtypes=week') > -1) {
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByResourceWeekFullResult,
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      if (req.url.search.indexOf('periodtypes=quarter') > -1) {
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByResourceQuarterFullResult,
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      return eventHandler({
        req,
        res,
        ctx,
        responseData: {
          '200': getGroupByResourceMonthFullResult,
          '204': getGroupByResourceEmptyResult,
        },
      });
    },
  ),
  rest.get('http://mockapi/api/v2/resource-planner/group-by-employee-total-row', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': {},
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/view-options', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getResourceViewOptions,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/migration-status', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getMigrationStatusComplete,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/search-resource-by-name', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getSearchResourceByName,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/search-task-by-name', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getSearchTaskByName,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/signalr-config', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': signalRConfigFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/filters/list/NewResourcePlanner', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': filterFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/filters/list/DesignSevenFrontPage', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': filterFullResult,
      },
    }),
  ),
  rest.post('http://mockapi/api/v2/ResourcePlanner', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': {} } }),
  ),
  rest.post(
    'http://mockapi/api/v2/resource-planner/create-work-item-relationship',
    (req, res, ctx) => eventHandler({ req, res, ctx, responseData: { '200': {} } }),
  ),
  rest.post(
    'http://mockapi/api/v2/resource-planner/start-initial-send-of-employees',
    (req, res, ctx) => eventHandler({ req, res, ctx, responseData: { '200': {} } }),
  ),
  rest.post('https://localhost:5001/hubs/resourcePlannerHub/negotiate', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': {} } }),
  ),
  rest.delete('http://mockapi/api/v2/resource-planner/work-item-relationship', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': {} } }),
  ),
  rest.get('http://mockapi/api/v2/revenue-forecast', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getRevenueForecast,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/revenue-forecast/view-options', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getRevenueForecastViewOptions,
      },
    }),
  ),
  rest.post('http://mockapi/api/v2/bookmarks', (req, res, ctx) => {
    changableValue.bookmark.push(generateBookmark(req.body));
    return eventHandler({ req, res, ctx, responseData: { '200': {} } });
  }),
  rest.delete('http://mockapi/api/v2/bookmarks/*', (req, res, ctx) => {
    changableValue.bookmark = changableValue.bookmark.filter(
      ({ Properties: { Url } }) => Url === (req?.body as any)?.Href,
    );
    return eventHandler({ req, res, ctx, responseData: { '200': {} } });
  }),
  rest.get('http://mockapi/api/v2/search/categories', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': searchCategoryAllNotSelectResult,
        '201': searchCategoryPartiallySelectResult,
        '204': searchCategoryEmptyResult,
      },
    }),
  ),
  rest.put('http://mockapi/api/v2/search/categories', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': searchCategoryAllNotSelectResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/feed', (req, res, ctx) => {
    const urlStatus = req.url.searchParams.get('status') ?? '200';
    switch (urlStatus) {
      case '204':
        return res(ctx.xml(newsFeedNoResults));
      case '401':
        return res(ctx.status(401), ctx.xml('<error>unauthorize user</error>'));
      case '500':
        return res(ctx.status(500), ctx.xml('<error>Internal server error</error>'));
      default:
        return res(ctx.xml(newsFeedFullResult));
    }
  }),
  rest.post('http://mockapi/api/v2/report-project/results/table', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': timeRegistrationsFullResult },
    }),
  ),
  rest.get('http://mockapi/api/v2/widget/all-active-widgets', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': reportingWidgetGetAll },
    }),
  ),
  rest.get(
    'http://mockapi/api/v2/insights-service/average-hourly-rate?$pagesize=5&$page=1',
    (req, res, ctx) =>
      eventHandler({
        req,
        res,
        ctx,
        responseData: { '200': getAverageHourlyRateFullResult },
      }),
  ),
  rest.get('http://mockapi/api/v2/insights-service/invoiced-per-month', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': getInvoicedPerMonthFullResult },
    }),
  ),
  rest.get(
    'http://mockapi/api/v2/insights-service/overdue-invoice?$pagesize=5&$page=1',
    (req, res, ctx) =>
      eventHandler({
        req,
        res,
        ctx,
        responseData: { '200': getOverdueInvoicesFullResult },
      }),
  ),
  rest.get(
    'http://mockapi/api/v2/insights-service/planned-absence?$pagesize=5&$page=1',
    (req, res, ctx) =>
      eventHandler({
        req,
        res,
        ctx,
        responseData: { '200': getPlannedAbsenceFullResult },
      }),
  ),
  rest.get(
    'http://mockapi/api/v2/insights-service/project-economy-health-invoice?$pagesize=5&$page=1',
    (req, res, ctx) =>
      eventHandler({
        req,
        res,
        ctx,
        responseData: { '200': getProjectEconomyHealthFullResult },
      }),
  ),
  rest.get('http://mockapi/api/v2/insights-service/time-tracking-delay', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': getTimeTrackingDelayFullResult },
    }),
  ),
  rest.get('http://mockapi/api/v2/insights-service/vacation-calculation', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': getVacationCalculationFullResult },
    }),
  ),
  rest.get('http://mockapi/api/v2/insights-service/hour-kpi-per-month', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': getAddedValuePerMonthFullResult },
    }),
  ),
  rest.get('http://mockapi/api/v2/insights-service/hour-kpi-bar-per-month', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': getAddedValueBarPerMonthFullResult },
    }),
  ),
  rest.get('http://mockapi/api/v2/insights-service/hour-kpi-per-month-privilege', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': getPrivilegeOptionsFullResult },
    }),
  ),
  rest.get('http://mockapi/api/v1/exchange-rate/distinct-currencies', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': getDistinctCurrenciesFullResult },
    }),
  ),
  rest.get('http://mockapi/api/v1/exchange-rate/periods', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': getPeriodsFullResult },
    }),
  ),
  rest.get(
    'http://mockapi/api/v1/exchange-rate/matrix-exchange-rates-by-period-id',
    (req, res, ctx) => {
      const periodId = req.url.searchParams.get('periodId');
      let matrixExchangeRateResult: typeof getMatrixExchangeRateFullResult | typeof getEmptyResult =
        getEmptyResult;
      switch (periodId) {
        case '313':
          matrixExchangeRateResult = getMatrixExchangeRateFullResult;
          break;
        case '312':
          matrixExchangeRateResult = matrixExchangeRateResultSetForPeriodId312;
          break;
        case '311':
          matrixExchangeRateResult = matrixExchangeRateResultSetForPeriodId311;
          break;
        default:
          matrixExchangeRateResult = getEmptyResult;
      }
      return eventHandler({
        req,
        res,
        ctx,
        responseData: { '200': matrixExchangeRateResult },
      });
    },
  ),
  rest.get('http://mockapi/api/v1/exchange-rate/matrix-actions', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': getMatrixActionsFullResult },
    }),
  ),
  rest.put('http://mockapi/api/v1/exchange-rate/toggle-period-lock', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.put('http://mockapi/api/v1/exchange-rate/automation-settings', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.put('http://mockapi/api/v1/exchange-rate/exchange-rate-by-id', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.put('http://mockapi/api/v1/exchange-rate/reset-exchange-rates', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.post('http://mockapi/api/v1/exchange-rate/manual-period', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': postManualPeriodFullResult,
        '500': { error: 'Internal server error' },
      },
    }),
  ),
  rest.delete('http://mockapi/api/v1/exchange-rate/period', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {} },
    }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/save-view', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getSavedViewResult,
      },
    }),
  ),
  rest.put('http://mockapi/api/v2/resource-planner/save-view', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.post('http://mockapi/api/v2/resource-planner/save-view', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': {},
        '500': { error: 'Internal server error' },
      },
    }),
  ),
  rest.delete('http://mockapi/api/v2/resource-planner/save-view', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {} },
    }),
  ),
  rest.get('http://mockapi/api/v2/employee-competences/available?userId=*', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': employeeCompetencesAvailableFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/employee-competences?userId=*&isActive=true', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': employeeCompetencesFullResult,
      },
    }),
  ),
  rest.post('http://mockapi/api/v2/employee-competences/bulk', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': null, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.put('http://mockapi/api/v2/employee-competences', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': null, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.delete('http://mockapi/api/v2/employee-competences/bulk/*', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.delete('http://mockapi/api/v2/employee-competences/*', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.get('http://mockapi/api/v2/competences/by-group', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': competencesByGroupFullResult,
      },
    }),
  ),
  rest.post('http://mockapi/api/v2/competences', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.delete('http://mockapi/api/v2/competences/*', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.put('http://mockapi/api/v2/competences', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.get('http://mockapi/api/v2/competence-groups', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': competenceGroupsFullResult,
      },
    }),
  ),
  rest.post('http://mockapi/api/v2/competence-groups', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.delete('http://mockapi/api/v2/competence-groups/*', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.put('http://mockapi/api/v2/competence-groups', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.get('http://mockapi/api/v2/employees', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': employeesFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/employees/*', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': employeeFullResult,
      },
    }),
  ),
  rest.put('http://mockapi/api/v2/employees/*', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.get('http://mockapi/api/v1/user/*', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': userFullResult,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/features/front-page', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getFrontPageFeaturesFullResult,
        '500': { error: 'Internal server error' },
      },
    }),
  ),
  rest.put('http://mockapi/api/v2/features', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: { '200': {}, '500': { error: 'Internal server error' } },
    }),
  ),
  rest.get('http://mockapi/api/v2/features/resource-management', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getResourceManagementFeaturesFullResult,
        '500': { error: 'Internal server error' },
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/projects', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getSearchProjects,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/projects/*/time-registrations', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getTimeRegistrations,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/projects/actions', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getActions,
        '500': { error: 'Internal server error' },
      },
    }),
  ),
  rest.put('http://mockapi/api/v2/projects/*?projectsIds=*', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': {
          Properties: {
            UpdatedProjectsCount: '1',
          },
        },
        '500': { error: 'Internal server error' },
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/filter-view/DesignSevenSearchProject', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getSearchProjectSaveView,
      },
    }),
  ),
];

export default handlers;
